<template>
    <div class="organization-in">
        <OrganizationForm :btn-text="'Обновить'"></OrganizationForm>
    </div>
</template>

<script>
    import OrganizationForm from '@/components/profilePage/OrganizationForm'
    export default {
        name: "OrganizationInfo",
        components: {
            OrganizationForm
        }
    }
</script>

<style scoped>
    .organization-in {
        padding-top: 8px;
        padding-bottom: 24px;
        max-width: 974px;
        text-align: left;
    }
</style>
